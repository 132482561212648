import { DEFAULT_LOCATIONS, ICUBA, CHECKMARK } from 'utils/constants';
import { selectToken } from 'utils/selectToken';
import IcubaLoginLogo from 'assets/pg-icuba-logo.png';

export const apiTokens = {
  dev: 'Token 4040c474466ff90d2301a21524ba85993018a939',
  staging: 'Token 34e3003e4598dad8a4047a1280f89738f1695c37',
  prod: 'Token e4775d4a455369d7d47c41d1f0c1d68848f4661f',
};

const token = selectToken(apiTokens);

const icubaConfig = {
  // config name used for fetching client config from fusion
  CONFIG_NAME: 'icuba',

  API_TOKEN: token,
  EMBOLD_DATA_TENANT: 'icuba',
  EMBOLD_CLIENT: ICUBA,
  EMBOLD_CLIENT_SLUG: 'icuba',

  // toggles
  SHOW_DISCLAIMER: false,
  USE_GENDER_ICONS: false,
  SHOW_LAYMANS_INTRO_MODAL: true,
  SHOW_BCBS_LINK: false,
  SHOW_DR_SCORE: false,
  SHOW_HIGH_PERFORMING_RIBBON: false,
  SHOW_BENEFIT_DIFF: true,
  HIGH_QUALITY_INTRO_MODAL: true,

  // login
  SHOW_REGION_SELECTOR: false,
  LOGIN_NETWORK_NAME: 'icubaUserId',
  LOGIN_COOKIE_AUTH_REGEX: ['icuba', 'i'],

  // other
  HIDE_CLIENT: true,
  DEFAULT_LOCATION: { ...DEFAULT_LOCATIONS.WestPalmBeach },

  // content
  DISPLAY_NAME: 'ICUBA',
  SCHEDULE_PHONE_NUMBER: '877-211-9127',
  HEADER_LOGO: IcubaLoginLogo,
  SCHEDULE_COPY: `Connecting you to Embold Health Customer Service. Click 'Call Now' to continue`,
  SUPPORT_LINK: 'https://icubasupport.emboldhealth.com/portal/',
  BENEFIT_DIFF_COPY: '$0 Copay',
  BENEFIT_DIFF_TITLE: 'Preferred Provider',
  BENEFIT_DIFF_TOOLTIP:
    'Embold is not a network of providers, it is your existing network of providers supercharged! You and your family members can use the Embold Provider Guide to find the right care, at the right time, and at the right price. If you see the $0 copay ribbon next to your doctor’s name, that means your doctor consistently delivers quality care, and your copay is waived for that visit!',
  FALSE_BENEFIT_DIFF_COPY: 'Copay Required',
  FALSE_BENEFIT_DIFF_TITLE: 'Copay Required',
  FALSE_BENEFIT_DIFF_TOOLTIP:
    'This provider does not meet requirements for the $0 copay benefit. A copay will be collected at your visit.',
  BENEFIT_DIFF_DETAIL_MARKDOWN:
    "This provider received a high-quality rating from Embold Health resulting in $0 Copay designation. You are not responsible for the usual and customary copay for office visits.  Your employer covers 100% of visit costs for you and any covered dependents.\n\n If you have any questions or concerns about your coverage, please contact BlueCross BlueShield at [800-868-2510](tel:800-868-2510). Providers, to learn more about Embold Health's methodology, contact [quality@emboldhealth.com](mailto:quality@emboldhealth.com).",
  EMBOLD_LOGO_PRESENT: true,
  WELCOME_MESSAGE: `ICUBA and Embold Health have partnered to bring you Embold's Provider Guide, your
  guide to quickly and easily finding the doctors in your network and area that have proven
  to provide the best care.`,
  LOCAL_LOGO_ASSET: IcubaLoginLogo,
  FORM_VARIANT: CHECKMARK,
  NO_WELCOME_LINK: false,
};

export default icubaConfig;
