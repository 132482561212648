import { DEFAULT_LOCATIONS, AXA, DROPDOWN } from 'utils/constants';
import { selectToken } from 'utils/selectToken';
import axaLogo from 'assets/axa_logo.png';

export const apiTokens = {
  dev: 'Token 39a44ac8bd073ce5e68deeb910713545ec245fe4',
  staging: 'Token 007a4e4c9217c5d2bf5487c9464986ecdb106b15',
  prod: 'Token a33060081d5ae186c3e484ed2df25386a4d99279',
};

const token = selectToken(apiTokens);

const axaConfig = {
  // config name used for fetching client config from fusion
  CONFIG_NAME: 'axa-partners',

  API_TOKEN: token,
  EMBOLD_DATA_TENANT: AXA,
  EMBOLD_CLIENT: AXA,
  EMBOLD_CLIENT_SLUG: AXA,

  // toggles
  SHOW_TELEHEALTH: false,
  SHOW_WHEELCHAIR_ACCESSIBLE: false,
  SHOW_DISCLAIMER: false,
  SHOW_LAYMANS_INTRO_MODAL: true,
  SHOW_BCBS_LINK: false,
  SHOW_DR_SCORE: true,
  SHOW_HIGH_PERFORMING_RIBBON: false,
  CLOSED_ACCESS: true,

  // login
  SHOW_REGION_SELECTOR: true,

  // other
  DEFAULT_LOCATION: { ...DEFAULT_LOCATIONS.Orlando },
  LOGIN_NETWORK_NAME: 'axaUserId',
  LOGIN_COOKIE_AUTH_REGEX: ['aetnachoiceposiiaxa|uhcchoiceplusaxa', 'i'],

  // content
  DISPLAY_NAME: 'Axa',
  SCHEDULE_PHONE_NUMBER: '',
  HEADER_LOGO: axaLogo,
  EMBOLD_LOGO_PRESENT: true,
  WELCOME_MESSAGE: `Axa and Embold Health have partnered to bring you Embold‘s Provider Guide, your guide to quickly and easily finding the doctors in your network and area that have proven to provide the best care.`,
  LOCAL_LOGO_ASSET: axaLogo,
  FORM_VARIANT: DROPDOWN,
  NO_WELCOME_LINK: true,
};

export default axaConfig;
