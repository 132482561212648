import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { select } from 'store/toolkit';
import { useHistory, withRouter, useParams } from 'react-router-dom';
import { PLACE_TYPE, PROVIDER_TYPE } from 'utils/constants';

import Modal from '../Modal';
import SearchAppendix from './SearchAppendix';

const useStyles = makeStyles(() => ({
  modal: {
    '& [data-reach-dialog-content]': {
      width: '100%',
      maxWidth: 756,
      padding: 0,
      height: '100vh',
    },
    '& .modal-content-wrapper': {
      height: '100%',
    },
  },
}));

const ARIA_ID = 'search-appendix';

function SearchAppendixModal() {
  const classes = useStyles();
  const { resultType } = useParams();
  const isXsDown = useSelector(select.ui.isXsDown);
  const history = useHistory();

  /**
   * On mobile screens, this modal will only ever be rendered from the /search route.
   * In that case, we need to go back, otherwise, the "goBack" called from the Mobile Search page will loop between this route and /search
   * For desktop, we use "push" so as to avoid the results re-loading
   * TODO: TECH-4696 consider using ModalRouter for /search route to avoid this conditional logic
   */
  const closeModal = useCallback(() => {
    if (isXsDown) history.goBack();
    else history.push(history.location?.state?.background || '/');
  }, [history, isXsDown]);

  if ([PROVIDER_TYPE, PLACE_TYPE].indexOf(resultType) === -1) {
    console.error(`Missing or invalid URL path: ${resultType}`);
    closeModal();
    return null;
  }

  return (
    <Modal
      handleClose={closeModal}
      open
      ariaId={ARIA_ID}
      hasDescription
      customStyles={classes.modal}
      fullScreen={isXsDown}
    >
      <SearchAppendix resultType={resultType} />
    </Modal>
  );
}

export default withRouter(SearchAppendixModal);
