import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { select, thunks } from 'store/toolkit';
import useSearchDispatchWithHistory from 'hooks/useSearchDispatchWithHistory';

const useStyles = makeStyles(() => ({
  root: {
    flexShrink: 0,
  },
}));

export default function SingleProviderSpecialtyButton(props) {
  const classes = useStyles();
  const searchDispatch = useSearchDispatchWithHistory();

  const [firstId] = useSelector(select.results.idList);
  // give specialty a default name of "This" to fall back to display of "Show More Providers With This Specialty"
  const { specialty = 'This' } = useSelector(select.provider(firstId).data) || {};

  const searchSingleProviderSpecialty = useCallback(
    () => searchDispatch(thunks.results.searchBySingleProvidersSpecialty()),
    [searchDispatch]
  );

  return (
    <Button
      onClick={searchSingleProviderSpecialty}
      classes={classes}
      variant="contained"
      color="primary"
      {...props}
    >
      Show More Providers With {specialty} Specialty
    </Button>
  );
}
